import List from "./List";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setresults } from "../features/filterSlice";
import "bootstrap/js/dist/dropdown"
import SearchNoFound from "./SearchNoFound";
import {setSidebar} from "../features/changeSidebar";

function Sidebar() {

  const dispatch = useDispatch();
  const datos = useSelector((state) => state.api.contact);
  const listEstado = useSelector((state) => state.filter.listEstado);
  const header = useRef(null);
  const lista = useRef(null);
  const [resize, setResize] = useState(null);
  //iconos de input search
   // console.log(Dropdown)
  const [ico, setIco] = useState(["bi bi-search"]);
  const [entrada, setEntrada] = useState("");

 const [list,setList]= useState(<List />)
    
    //console.info(Dropdown)


  useEffect(() => {
    function sscroll() {
      let ventana = window.innerHeight;
      //console.log(header.current.clientHeight);
        if(header.current === null){
           window.removeEventListener("resize", () => {
      sscroll();
    });
            return
        }
      let scroll = header.current.clientHeight;
      setResize(ventana - scroll);
    }

    sscroll();
    window.addEventListener("resize", () => {
      sscroll();
    });
  }, []);

  // función que devuelve true si todas las palabras están incluidas en el texto
  function allWordsIncluded(words, text) {
    if (!words.length) return false;
    for (let i = 0; i < words.length; i++) {
      if (!text.includes(words[i])) return false;
    }
    return true;
  }

  function list_filt(search) {
    let nd = [];
    let index_res = [];
    let fdato = [];
    //console.log(datos)
    datos.forEach((x) => {
      nd.push(`${x.name.first} ${x.name.last} ${x.email} ${x.phone}`);
    });

    //let res= allWordsIncluded("abi", nd[0])
    const patron = search.toLowerCase().trim();
    nd.forEach((x, index) => {
      let r = allWordsIncluded(patron.split(" "), x);
      if (r) {
        index_res.push(index);
      }
    });

    index_res.forEach((x) => {
      fdato.push(datos[x]);
   });

    if (listEstado) {
      return datos;
    } else {
        //console.log(fdato.length, fdato === [])
        if(fdato.length === 0){
            const ns= <SearchNoFound /> 
            setList(ns)    
        }else{
            setList(<List />)
        }
      return fdato;
    }
  }
  //const rr = useSelector((state) => state.filter.people);
  function search(value) {
    const fr = list_filt(value);
    //console.log(value)
    dispatch(setresults(fr));
    //console.log(rr)

    setEntrada(value);
    if (value === "") {
      setIco(["bi bi-search animate__animated animate__flipInY animate__slow"]);
    } else {
      setIco(["bi bi-x-lg animate__animated animate__rotateIn animate__slow"]);
    }
    //    console.log("Ok",value)
  }

  function reset() {
    search("");
  }

  useEffect(() => {
    search("");
    // eslint-disable-next-line
  }, []);

  return (
    <section className="vh-100 animate__animated animate__slideInDown">
      <div ref={header} id="header" className="bg-gray">
        <div className="row py-1 w-100 m-0 d-flex align-items-center justify-content-center">
          <div className="col-3">
            <i className="bi bi-arrow-left fw-bold fs-2 cursor-pointer"
               onClick={()=> dispatch( setSidebar(false) ) }
            ></i>
          </div>
          <div className="col-6">
            <p className="fs-4 py-1 m-0 user-select-none">Contacts</p>
          </div>
          <div className="col-2">

            <div className="dropdown">
                <button 
                    className="btn rounded-circle" 
                    type="button" id="dropdownMenuButton1" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots-vertical text-white"></i>
                </button>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a className="dropdown-item" href="#/">Help</a></li>
                    <li><a className="dropdown-item" href="#/">Invite</a></li>
                </ul>
            </div>

          </div>
        </div>
        <div className="row justify-content-center w-100 m-0">
          <div className="col-11 pt-1 pb-2">
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => search(e.target.value)}
                placeholder="Search Contacts"
                className="form-control form-control-sm rounded-pill bg-search w-100 pe-32"
                value={entrada}
              />
              <button
                className="btn btn-sm shadow-none z-3 text-white rounded-circle position-absolute end-0 top-0"
                onClick={() => reset()}
              >
                <i className={ico}></i>
              </button>
            </div>
      {/*
          <p className="small text-center my-1 user-select-none">
          {datos.length} Contacts
          </p>*/
      }
            
          </div>
        </div>
      </div>
      <div
        ref={lista}
        className="w-100 overflow contenedor"
        style={{ height: `${resize}px` }}
      >
      { list }
      </div>
    </section>
  );
}

export default Sidebar;
