import LazyImage from "./Lazyimg";
import "bootstrap/js/dist/dropdown";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../assets/img/Profile_avatar_placeholder_large.png";
import {setSidebar} from "../features/changeSidebar";

function Inisidebar() {
  const datos = useSelector((state) => state.api.contact);
  const dispatch= useDispatch()
  //console.log(datos)

  return (
    <section className="vh-100 position-relative  animate__animated animate__slideInDown ">
      <div id="header" className="bg-gray">
        <div className="row py-1 w-100 m-0 d-flex align-items-center justify-content-between px-2">
          <div className="col-3">
            <LazyImage
              src={datos[50].picture.thumbnail}
              placeholder={avatar}
              classn="img-fluid rounded-circle border border-primary"
              alt=""
              title={`${datos[51].name.first} ${datos[52].name.last}`}
            />
          </div>
          
          <div className="col-2">
            <div className="dropdown">
              <button
                className="btn rounded-circle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-three-dots-vertical text-white"></i>
              </button>

              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="#/">
                    Help
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#/">
                    Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#/">
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    <div>
      msg
      </div>
      

      
        <button className="btn btn-primary rounded-circle position-absolute btn-f"
      title="new chat"
      onClick={ ()=>{
          dispatch( setSidebar(true) )
      }}>
<i className="bi bi-chat-dots-fill fs-4"></i>
        </button>


    </section>
  );
}

export default Inisidebar;
