import React, {useEffect, useState} from 'react';
import Init from './component/Init';
import Main from './component/Main';
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./assets/css/index.css"
import "animate.css"

function App() {

const [dato, setDato]= useState(<Init />)
const done = useSelector((state) => state.api.done);


useEffect(() => {

function start(){
    if(done){
        setTimeout( ()=>{setDato(<Main />)}, 2000 )
    }
}

    start();
},[done])

  return (
      <div style={{maxWidth:'1450px',marginRight:'auto',marginLeft:'auto'}}>
        { dato } 
      </div>
  );
}

export default App;
