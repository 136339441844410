import { createSlice } from "@reduxjs/toolkit";

export const jsonSlice = createSlice({
  name: "results",
  initialState: {
    people: [],
    list_estado: false
  },
  reducers: {
    setresults: (state, action) => {
      state.people = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setresults } = jsonSlice.actions;

export default jsonSlice.reducer;


