import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LazyImage from "./Lazyimg";
import avatar from "../assets/img/Profile_avatar_placeholder_large.png"

function List() {
  const datos = useSelector((state) => state.filter.people);
  let letra = undefined;
  const [ani,setAni]= useState(true)
  const [clase,setClase]= useState("animate__fadeInLeft")

    useEffect(()=>{
        setAni( a => !a )
        if(ani){
            setClase("animate__fadeInLeft")
        }else{
            setClase("animate__fadeInRight")
        }

// eslint-disable-next-line
    },[datos.length])
 
  return (
    <div>
      <ul className="list-group">
        {datos.map((item, index) => {
          const s = (a) => {
            //console.log(letra, " de objeto:", a.name.first.substring(0, 1));
            //console.log(!a.name.first.substring(0, 1) === letra);
            if (a.name.first.substring(0, 1) === letra) {
              return;
            } else {
              letra = a.name.first.substring(0, 1);
              return (
                <li className={ [ `list-group-item py-3 user-select-none animate__animated ${clase}` ] }>
                  <span className="text-success fw-bold fs-5">
                    {a.name.first.substring(0, 1)}
                  </span>
                </li>
              );
            }
            //console.log(letra)
          };

          return (
            <Fragment key={index}>
              {s(item)}
              <li className={ [ `list-group-item list-group-item-action cursor-pointer animate__animated ${clase}` ] }>
                <div className="row">
                  <div className="col-3 px-1">
                    <LazyImage
                      src={item.picture.thumbnail}
                      placeholder={avatar}
                      classn="img-fluid rounded-circle border border-primary tavatar"
                      alt=""
                      title={`${item.name.first} ${item.name.last}`}
                    />
                  </div>
                  <div className="col-9 px-0">
                    <p
                      title={`${item.name.first} ${item.name.last}`}
                      className="fw-bold mb-0 txt-g"
                    >
                      {`${item.name.first} ${item.name.last}`}
                    </p>
                    <p
                      className="small text-secondary txt-g"
                      title={item.email}
                    >
                      {item.email}
                    </p>
                  </div>
                </div>
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
}

export default List;
