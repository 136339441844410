import { useDispatch, useSelector } from "react-redux";
import {
  progress,
  callapi,
  estado,
  finale,
  estadodecarga,
} from "../features/jsonSlice.js";
import { useEffect, useState } from "react";
import Logo from "../assets/img/logo.svg"

function Init() {
  const dispatch = useDispatch();
  // const count = useSelector((state) => state.api.contact);
  const progreso = useSelector(progress);
  const carga = useSelector((state) => state.api.cargado);
  const done = useSelector((state) => state.api.done);

  //preparando descarga
  function Pd(t) {
    dispatch(estado(t));
  }

  const [procesos, setProcesos]= useState("");
  const [spinner, setSpinner]= useState("");

  useEffect(() => {
    //Initializing
    const ini = (callback) => {
      dispatch(estado(10));
      setProcesos("Initializing...");
      setSpinner("text-danger")
      callback();
    };
    //preparing the download
    const two = (callback) => {
      setTimeout(() => {
        Pd(40);
        setProcesos("Preparing the Download...");
      setSpinner("text-warning")
        callback();
      }, 1500);
    };
    //Downloading
    const three = () => {
      dispatch(callapi("downloaded data :)"));
      //setProcesos("Downloading...");
    };

    //  console.log(carga)

    ini(() =>
      two(() => {
        three();
      })
    );
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (carga) {
    //configurando entorno
    const four = () => {
      setTimeout(() => {
        Pd(70);
        dispatch(estadodecarga(false));
        dispatch(finale(true));
      setProcesos("Downloading...");
      setSpinner("text-success")
      }, 100);
    };
    four();
  }

  if (done) {
    //listo
    const five = () => {
      setTimeout(() => {
        Pd(100);
      setProcesos("Done...");
      setSpinner("text-primary")
      }, 1500);
    };
    five();
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-5 col-md-6 col-sm-8 col-10">

          <div className="row justify-content-center">
            <div className="col-md-5 col-6">
                <img src={Logo} className="img-fluid" alt=""/>
            </div>
          </div>

          <div className="progress mt-2" style={{ height: "4px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progreso}%` }}
            ></div>
          </div>

          <div className="row justify-content-center align-items-center mt-3">
            <div className="col-auto">
              <div className={`spinner-border ${ spinner }`} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            <div className="col-auto">
              <p className="m-0">{ procesos }</p>
            </div>
          </div>

            <p className="text-secondary text-center small mb-5 mt-2">
                <span className="mx-2">
                    <i className="bi bi-shield-lock"></i>
                </span>
                <span>
                    End-to-end encryption
                </span>
            </p>

            <div className="row justify-content-center">
                <div className="col-auto">
                  <div className="btn btn-secondary mt-5 d-flex justify-content-center">
                    Log Out
                  </div>
                </div>
            </div>

        </div>
      </div>
    </div>
 );
}

export default Init;
