function SearchNoFound(){
    return(
        <div className="animate__animated animate__fadeInDown">
        <div className="row mt-5 mx-0 justify-content-center w-100">

        <div className="col-3 text-center animate__animated  animate__swing animate__infinite">
        <i className="bi bi-emoji-frown fs-1"></i>
        </div></div>
        <p className=" text-center">No matches found</p>
        </div>
    )
}

export default SearchNoFound;
