import { configureStore } from '@reduxjs/toolkit';
import changeSidebar from '../features/changeSidebar';
import filterSlice from '../features/filterSlice';
import jsonSlice from '../features/jsonSlice';

export const store = configureStore({
  reducer: {
    api: jsonSlice,
    filter: filterSlice,
    sidebar: changeSidebar
  },
});
