import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

export const jsonSlice = createSlice({
  name: "api",
  initialState: {
    contact: "hola",
    progress: 0,
    cargado: false,
    done: false,
  },
  reducers: {
    estado: (state, action) => {
      state.progress = action.payload;
    },

    domwload: (state, action) => {
      state.contact = action.payload;
    },
    estadodecarga: (state, action) => {
      state.cargado = action.payload;
    },
    finale: (state, action) => {
      state.done = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { domwload, estado, estadodecarga, finale } = jsonSlice.actions;

export default jsonSlice.reducer;

export const callapi = (amount) => (dispatch) => {
  async function data() {
    const url = await axios.get("https://randomuser.me/api/?results=100");
    const res = url.data.results;
    console.log(amount);
    dispatch(estadodecarga(true));

const dato_clonado = res.slice();
    const Lista_ordenada = dato_clonado.sort((a, b) => {
      if (a.name.first > b.name.first) {
        return 1;
      }
      if (a.name.first < b.name.first) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });


    dispatch(domwload(Lista_ordenada));
  }
  data();
};

export const progress = (state) => state.api.progress;
