//import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import Inisidebar from "./Inisidebar";
import Sidebar from "./Sidebar";


function Main(){


  const statusSidebar = useSelector((state) => state.sidebar.sidebar_estado);

const cSidebar= (bool) =>{
    if(bool){
        return <Sidebar />
    }else{
        return <Inisidebar />
    }
}

    return(
        <div className="container-fluid">
            <div className="row animate__animated animate__zoomIn">
                <div className="col-lg-3 col-md-4 px-0">
        { cSidebar(statusSidebar) }

                </div>
                <div className="col-lg-9 col-md-8 bg-secondary">
                    <h2>
                        h2
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Main;
