import { createSlice } from "@reduxjs/toolkit";

export const changeSidebar = createSlice({
  name: "changeSidebar",
  initialState: {
    sidebar_estado: false
  },
  reducers: {
    setSidebar: (state, action) => {
      state.sidebar_estado = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSidebar } = changeSidebar.actions;

export default changeSidebar.reducer;

